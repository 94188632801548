<template>
  <div class="content-wrapper">
    <div class="row content-header">
      <div class="content-header-left mb-2 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12 d-flex align-items-center justify-content-between">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Thời gian mở cửa
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <div class="row match-height">
        <div class="col-12">
          <div
            data-v-5cff0742=""
            class="card"
          ><!----><!---->
            <div
              data-v-5cff0742=""
              class="card-header"
            />
            <div
              data-v-5cff0742=""
              class="card-body"
            ><!----><!---->
              <form
                data-v-5cff0742=""
                class=""
              >
                <div
                  data-v-5cff0742=""
                  class="row"
                >
                  <div
                    data-v-5cff0742=""
                    class="col-12"
                  >
                    <div
                      id="__BVID__824"
                      role="group"
                      class="form-row form-group"
                      data-v-5cff0742=""
                    ><label
                       id="__BVID__824__BV_label_"
                       for="h-first-name"
                       class="col-md-2 col-form-label"
                     >
                       Time Open</label>
                      <div class="col">
                        <select v-model="time_open" name="" class="form-control" id="">
                          <option value="18">01:00 AM</option>
                          <option value="19">02:00 AM</option>
                          <option value="20">03:00 AM</option>
                          <option value="21">04:00 AM</option>
                          <option value="22">05:00 AM</option>
                          <option value="23">06:00 AM</option>
                          <option value="24">07:00 AM</option>
                          <option value="1">08:00 AM</option>
                          <option value="2">09:00 AM</option>
                          <option value="3">10:00 AM</option>
                          <option value="4">11:00 AM</option>
                          <option value="5">12:00 AM</option>
                          <option value="6">13:00 PM</option>
                          <option value="7">14:00 PM</option>
                          <option value="8">15:00 PM</option>
                          <option value="9">16:00 PM</option>
                          <option value="10">17:00 PM</option>
                          <option value="11">18:00 PM</option>
                          <option value="12">19:00 PM</option>
                          <option value="14">20:00 PM</option>
                          <option value="14">21:00 PM</option>
                          <option value="15">22:00 PM</option>
                          <option value="16">23:00 PM</option>
                          <option value="19">24:00 PM</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-5cff0742=""
                    class="col-12"
                  >
                    <div
                      id="__BVID__826"
                      role="group"
                      class="form-row form-group"
                      data-v-5cff0742=""
                    ><label
                       id="__BVID__826__BV_label_"
                       class="col-md-2 col-form-label"
                     >Time Close</label>
                      <div class="col">
                        <select v-model="time_close" name="" class="form-control" id="">
                          <option value="18">01:00 AM</option>
                          <option value="19">02:00 AM</option>
                          <option value="20">03:00 AM</option>
                          <option value="21">04:00 AM</option>
                          <option value="22">05:00 AM</option>
                          <option value="23">06:00 AM</option>
                          <option value="24">07:00 AM</option>
                          <option value="1">08:00 AM</option>
                          <option value="2">09:00 AM</option>
                          <option value="3">10:00 AM</option>
                          <option value="4">11:00 AM</option>
                          <option value="5">12:00 AM</option>
                          <option value="6">13:00 PM</option>
                          <option value="7">14:00 PM</option>
                          <option value="8">15:00 PM</option>
                          <option value="9">16:00 PM</option>
                          <option value="10">17:00 PM</option>
                          <option value="11">18:00 PM</option>
                          <option value="12">19:00 PM</option>
                          <option value="14">20:00 PM</option>
                          <option value="14">21:00 PM</option>
                          <option value="15">22:00 PM</option>
                          <option value="16">23:00 PM</option>
                          <option value="19">24:00 PM</option>
                        </select>
                        <!----><!----></div>
                    </div>
                  </div>
                  <div
                    data-v-5cff0742=""
                    class="col-12"
                  >
                    <div
                      id=""
                      role="group"
                      class="form-row form-group"
                      data-v-5cff0742=""
                    ><label
                       id=""
                       class="col-md-2 col-form-label"
                     >Trạng Thái</label>
                      <div class="col">
                        <input
                          id="close"
                          v-model="status"
                          type="radio"
                          value="false"
                          name="status"
                        >
                        <label
                          style="font-size: 16px;padding-left: 10px"
                          for="close"
                        >Đóng</label><br>

                        <input
                          id="open"
                          v-model="status"
                          type="radio"
                          value="true"
                          name="status"
                        >
                        <label
                          style="font-size: 16px;padding-left: 10px"
                          for="open"
                        >Mở</label><br>
                        <!----><!----></div>
                    </div>
                  </div>
                  <div
                    data-v-5cff0742=""
                    class="offset-md-4 col"
                  >
                    <button
                      data-v-5cff0742=""
                      type="button"
                      class="btn mr-1 btn-primary"
                      @click.prevent="submitUpdateTimeOpen()"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div><!----><!----></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex/dist/vuex.esm.browser'

export default {
  name: 'TimeOpen',
  data() {
    return {
      time_open: 0,
      time_close: 0,
      status: 'true',
    }
  },
  created() {
    // this.getMe({}).then(res => {
    //   console.log('GET_ME', res)
    //   this.status = res.data.data.is_open
    //   this.time_open = res.data.data.time_open
    //   this.time_close = res.data.data.time_close
    // }).catch(e => {
    //   console.log(e)
    // })
  },
  methods: {
    ...mapActions(['updateTimeOpen']),
    submitUpdateTimeOpen() {
      const data = {
        is_open: this.status,
        time_open: this.time_open,
        time_close: this.time_close,
      }
      console.log('data submitUpdateTimeOpen', data)
      this.updateTimeOpen(data)
    },
  },
}
</script>

<style scoped>

</style>
